import React, { useState, useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CIcon from "@coreui/icons-react";
import { cilLockLocked } from "@coreui/icons";
import AHNLogo from "../../../assets/images/ahn_logo_on_20-9-2023-removebg-preview.png";
import { resetPasswordAction } from "src/redux/action";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import SecurityIcon from '@mui/icons-material/Security';
import LockIcon from '@mui/icons-material/Lock';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resetErr, setResetErr] = useState("");
  const [firstLogin, setFirstLogin] = useState(false);
  const [token, setToken] = useState("");
  const [passwordError, setPasswordError] = useState("");


  const tokenFromUrl = new URLSearchParams(location.search).get("token");
console.log("tokenFromUrl",tokenFromUrl)
  useEffect(() => {
    if (tokenFromUrl) {
      setToken(tokenFromUrl); 
    } else {
      console.error("Token not found in URL.");
    }
  }, [tokenFromUrl]); 


  useEffect(() => {
    const storedToken = localStorage.getItem("access_token");
    if (storedToken) {
      setToken(storedToken);
    } else {
      console.error("Access token not found.");
    }
  }, []);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setResetErr("");
    if (newPassword.length < 12) {
      setPasswordError("Password must be at least 12 characters long");
    } else {
      setPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setResetErr("");
  };

  const handleResetPassword = () => {
    if (password === confirmPassword) {
      dispatch(resetPasswordAction({ password, ConfirmPassword: password, first_login: firstLogin, token }))
        .then((response) => {
          console.log("Response from password reset:", response);
  
          if (response?.status === 200) {
            localStorage.removeItem("access_token"); 
            navigate("/login");
          } else {
            console.error("Password setup failed:", response?.message || "Unknown error");
            setResetErr(response?.message || "An error occurred. Please try again.");
          }
        })
        .catch((error) => {
          console.error("Password reset failed:", error);
          setResetErr("An error occurred. Please try again.");
        });
    } else {
      console.error("Passwords do not match.");
      setResetErr("Passwords do not match.");
    }
  };
  
  const isButtonDisabled = !password || !confirmPassword || password.length < 12 || password !== confirmPassword;


  return (
    <div className="flex-row h-100">
      <CRow className="justify-content-center">
        <CCol md={7} style={{ background: "#0E163A" }} className="min-vh-100">
          <div className="d-flex align-items-center justify-content-center h-100 w-100">
            <img src={AHNLogo} alt="/" width={"70%"} />
          </div>
        </CCol>
        <CCol md={5}>
          <CCardGroup className="h-100">
            <CCard className="p-4">
              <CCardBody className="d-flex justify-content-center align-items-center">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <CForm>
                  <div >
                    <h5 style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#333",
                        margin: "0",
                        display: "flex",
                        alignItems: "center", 
                        justifyContent: "center",
                        gap: "10px"
                    }}>
                        <SecurityIcon style={{ color: "#007bff" }} />
                        Hi User, please complete the process to change your password.
                    </h5>
                </div>

                    <h5 className="text-center pb-2">Setup Password</h5>

                    <CInputGroup className="mb-2 mt-3">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type={showPassword ? "text" : "password"}
                        placeholder="New Password"
                        value={password}
                        onChange={handlePasswordChange}
                      />
                      <CInputGroupText
                        onClick={() => setShowPassword(!showPassword)}
                        style={{ cursor: "pointer" }}
                      >
                        {!showPassword ? (
                          <VisibilityIcon style={{ cursor: "pointer" }} />
                        ) : (
                          <VisibilityOffIcon style={{ cursor: "pointer" }} />
                        )}
                      </CInputGroupText>
                    </CInputGroup>
                    {passwordError && (
                      <div style={{ color: "red" }} className="mb-2">
                        {passwordError}
                      </div>
                    )}

                    <CInputGroup className="mb-2 mt-3">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type={showConfirmPassword ? "text" : "password"}
                        placeholder="Confirm New Password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                      />
                      <CInputGroupText
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        style={{ cursor: "pointer" }}
                      >
                        {!showConfirmPassword ? (
                          <VisibilityIcon style={{ cursor: "pointer" }} />
                        ) : (
                          <VisibilityOffIcon style={{ cursor: "pointer" }} />
                        )}
                      </CInputGroupText>
                    </CInputGroup>

                    <div style={{ color: "red" }} className="mb-2">
                      {resetErr}
                    </div>

                    <CRow>
                      <CCol xs={6} className="text-center w-100 px-0 ">
                        <CButton
                          color="primary"
                          className="w-100 mt-2"
                          onClick={handleResetPassword}
                          disabled={isButtonDisabled}                        >
                          Setup Password
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </div>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </div>
  );
};

export default ResetPassword;
