import React, { useState, useEffect } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CRow,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import AHNLogo from "../../../assets/images/ahn_logo_on_20-9-2023-removebg-preview.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'; 
import { forgotPasswordAction, emailAction } from "src/redux/action";  
const ForgetPass = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [loading, setLoading] = useState(false); 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setEmailError("");  

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    setDebounceTimeout(
      setTimeout(() => {
        if (emailPattern.test(emailValue)) {
          setLoading(true); 
          dispatch(emailAction(emailValue))
            .then(() => {
              setIsEmailVerified(true); 
              setLoading(false); 
            })
            .catch(() => {
              setIsEmailVerified(false); 
              setLoading(false);  
            });
        } else {
          setIsEmailVerified(false);  
        }
      }, 500) 
    );
  };

  const handleSubmit = () => {
    if (!email) {
      setEmailError("Email is required");
      return;
    }

    dispatch(forgotPasswordAction({ payload: { email } }))
      .then(() => {
        navigate("/check-your-email");  
      })
      .catch((error) => {
        console.error("Error during password reset request", error);
      });
  };

  const handleEmailBlur = () => {
    if (!email) {
      setEmailError("Email is required");
      return;
    }
    
    if (!emailPattern.test(email)) {
      setEmailError("Please enter a valid email");
      setIsEmailVerified(false);
    } else {
      setEmailError(""); 
    }
  };

  return (
    <div className="flex-row h-100">
      <CRow className="justify-content-center">
        <CCol md={7} style={{ background: "#0E163A" }} className="min-vh-100">
          <div className="d-flex align-items-center justify-content-center h-100 w-100">
            <img src={AHNLogo} alt="Logo" width={"70%"} />
          </div>
        </CCol>
        <CCol md={5}>
          <CCardGroup className="h-100">
            <CCard className="p-4">
              <CCardBody className="d-flex justify-content-center align-items-center">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <CForm>
                    <h4 className="text-center pb-2">Forgot Password</h4>

                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <EmailOutlinedIcon />
                      </CInputGroupText>
                      <CFormInput
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={handleEmailChange}  
                        onBlur={handleEmailBlur} 
                      />
                    </CInputGroup>

                    {emailError && <div style={{ color: "red" }}>{emailError}</div>}
                    {!isEmailVerified && email && !loading && (
                      <div style={{ color: "red" }}>Email not verified.</div>
                    )}
                    {loading && <div>Loading...</div>} 

                    <CRow>
                      <CCol xs={12} className="text-center w-100 px-0 ">
                        <CButton
                          color="primary"
                          className="w-100 mt-2"
                          onClick={handleSubmit}
                          disabled={!email || !isEmailVerified || loading} 
                        >
                          Submit
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </div>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </div>
  );
};

export default ForgetPass;
