import { Dispatch } from "redux";
import {
  deleteWithToken,
  getApiwith,
  postFormDataWithToken,
  postLoginApi,
} from "../../utils/request";
import { setCookie } from "../../utils/cookie";
import { jwtDecode } from "jwt-decode";
import {
  citiesList,
  followersList,
  topPostList,
  userActivityData,
} from "../../utils/fixture";
import {
  getWithToken,
  getWithUrl,
  putWithToken,
  postApi,
  postWithToken,
  postWithUrl,
} from "../../utils/request";
import axios from "axios";
import toast from "react-hot-toast";
import { duration } from "moment";

export const LOGIN_Action = "LOGIN_Action";

export const CATEGORY_LIST = "CATEGORY_LIST ";
export const CATEGORY_EXPORT_LIST = "CATEGORY_EXPORT_LIST ";
export const PODCAST_LIST = "PODCAST_LIST ";
export const SUB_CATEGORY_LIST = "CATEGORY_LIST ";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

export const loginAction = (payload) => {
  return async function (dispatch) {
    toast.loading("Authenticating....");
    try {
      const response = await postLoginApi("/auth/login", payload);
      // console.log("response await", response);
      if (response.status === 200) {
        const result = response.data;

        const decodedValue = jwtDecode(response?.data?.access_token);

        const issuer_url = "https://ahn24x7.com";
        const sub_id = "662b6657b492303bd0265c95-ahn24x7";
        if (decodedValue?.iss === issuer_url && decodedValue?.subw === sub_id) {
          sessionStorage.setItem("token", `Bearer ${result.access_token}`);
          sessionStorage.setItem("refreshToken", `${result.refresh_token}`);
          sessionStorage.setItem("role", `${result.role}`);
          sessionStorage.setItem("user_name", `${result?.user_name}`);
          // get csrf value
          getApiwith("/auth/csrf_token")
            .then((res) => {
              // console.log("csrf res", res?.data?.data);
              sessionStorage.setItem("csrf_token", res?.data?.data);
            })
            .catch((err) => {
              console.error("CSRF request failed!!", err);
            });
          toast.dismiss();
        } else {
          toast.dismiss();
          toast.error("Login failed !!");
        }
      }

      return response;
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
      console.error("Login error:", error);
    }
  };
};

export const resetPasswordAction = (payload) => {
  return async function (dispatch) {
    toast.loading("setup password...");
    try {
      const response = await postWithToken("/auth/setup_password", payload);

      if (response.status === 200) {
        dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response.data });
        toast.dismiss();
        toast.success("Password setup successful.");
        return response;
      } else {
        dispatch({ type: RESET_PASSWORD_FAILURE, payload: response.data });
        toast.dismiss();
        toast.error("Password setup failed. Please try again.");
        return response
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Error setup password. Please try again.");
      console.error("Error setup password:", error);
    }
  };
};
export const forgotPasswordAction = (payload) => {
  return async function (dispatch) {
    toast.loading("Resetting password...");
    try {
      const response = await postWithToken("/auth/forgot_password", payload);

      if (response.status === 200) {
        dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response.data });
        toast.dismiss();
        toast.success("Password reset successful.");
      } else {
        dispatch({ type: RESET_PASSWORD_FAILURE, payload: response.data });
        toast.dismiss();
        toast.error("Password reset failed. Please try again.");
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Error resetting password. Please try again.");
      console.error("Error resetting password:", error);
    }
  };
};
export const multidelAction = (payload) => {
  return async function (dispatch) {
    toast.loading("Deleting ...");
    try {
      const response = await deleteWithToken("/auth/delete", payload);

      if (response.status === 200) {
        dispatch({ type: DELETE_SUCCESS, payload: response.data });
        toast.dismiss();
        toast.success("deleted successfully.");
      } else {
        dispatch({ type: DELETE_FAILURE, payload: response.data });
        toast.dismiss();
        toast.error("Deletion failed. Please try again.");
      }
    } catch (error) {
      toast.dismiss();
      // toast.error("Error deleting authors. Please try again.");
      console.error("Error deleting author:", error);
    }
  };
};
// export const resendEmailAction = (payload) => {
//   return async function (dispatch) {
//     toast.loading("Resending email...");
//     try {
//       const response = await postWithToken("/auth/resend/email", payload);

//       if (response.status === 200) {
//         console.log("responseis", response)
//         dispatch({ type: RESEND_EMAIL_SUCCESS, payload: response.data });
//         toast.dismiss();
//         toast.success("Email resent successfully.");
//       } else {
//         dispatch({ type: RESEND_EMAIL_FAILURE, payload: response.data });
//         toast.dismiss();
//         toast.error("Failed to resend email. Please try again.");
//       }
//     } catch (error) {
//       toast.dismiss();
//       console.error("Error resending email:", error);
//       toast.error("An error occurred while resending the email. Please try again.");
//     }
//   };
// };

export const resendEmailAction = (payload) => {
  return async function (dispatch) {
    toast.loading("Resending email...");
    try {
      const response = await postWithToken("/auth/resend/email", payload);

      if (response.status === 200) {
        console.log("responseis", response);
        dispatch({ type: RESEND_EMAIL_SUCCESS, payload: response.data });
        toast.dismiss();
        toast.success("Email resent successfully.");
      } else {
        dispatch({ type: RESEND_EMAIL_FAILURE, payload: response.data });
        toast.dismiss();
        toast.error("Failed to resend email. Please try again.");
      }
    } catch (error) {
      toast.dismiss();

      const errorMessage = error.message || "An error occurred while resending the email. Please try again.";
      console.error("Error resending email:", errorMessage);
      toast.error(errorMessage);
    }
  };
};


export const updateUserStatus = (id, userData) => {
  return async function (dispatch) {
    const loadingToast = toast.loading("Updating status...");
    
    try {
      const response = await putWithToken(`/auth/${id}`, userData);

      toast.dismiss(loadingToast);

      if (response.status === 200) {
        dispatch({ type: 'UPDATE_USER_STATUS_SUCCESS', payload: response.data });
        toast.success("Status updated successfully");
        return true;
      } else {
        toast.error("Failed to update status");
        return false;
      }
    } catch (error) {
      toast.dismiss(loadingToast);
      toast.error("Error updating status");
      console.error("Error updating status:", error);
      return false;
    }
  };
};

export const emailAction = (email) => {
  return async function (dispatch) {
    toast.loading("Verifying email...");
    try {
      const encodedEmail = encodeURIComponent(email);
      const response = await getWithToken(`/auth/verify_email?email=${encodedEmail}`);

      if (response.status === 200) {
        dispatch({  type: VERIFY_EMAIL_SUCCESS, payload: response.data });
        toast.dismiss();
        toast.success("Email verified successfully.");
      } else {
        dispatch({ type: VERIFY_EMAIL_FAILURE, payload: response.data });
        toast.dismiss();
        toast.error("Email verification failed.");
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Error during email verification.");
      console.error("Error verifying email:", error);
    }
  };
};

// get categories
export const getCategoryList = ({
  search,
  status,
  startDate,
  endDate,
  skip,
  limit,
}) => {
  return async (dispatch) => {
    try {
      let url = `/category/?skip=${skip || 0}&limit=${limit || 10}`; // Corrected base URL

      const queryParams = [];
      if (search) queryParams.push(`search=${search}`);
      if (status) queryParams.push(`status=${status}`);
      if (startDate) {
        queryParams.push(`start_ts=${startDate}`);
      }
      if (endDate) {
        queryParams.push(`end_ts=${endDate}`);
      }

      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }

      const response = await getWithToken(url);

      if (response) {
        const category = response.data.data;

        dispatch({
          type: CATEGORY_LIST,
          payload: category || [],
          totalCount: response.data.count,
          skip: skip,
          limit: limit,
        });

        return response;
      }
    } catch (error) {
      dispatch({
        type: CATEGORY_LIST,
        payload: [],
      });
      toast.error(error.message || error.detail[0].msg);
      console.error("API error:", error);
    }
  };
};

// export all categories api
export const getAllCategoryExportList = ({
  search,
  status,
  startDate,
  endDate,
}) => {
  return async (dispatch) => {
    try {
      let url = `/category/without/nested`; // Corrected base URL

      const queryParams = [];
      if (search) queryParams.push(`search=${search}`);
      if (status) queryParams.push(`status=${status}`);
      if (startDate) {
        queryParams.push(`start_ts=${startDate}`);
      }
      if (endDate) {
        queryParams.push(`end_ts=${endDate}`);
      }

      if (queryParams.length > 0) {
        url += `?${queryParams.join("&")}`;
      }

      const response = await getWithToken(url);

      if (response) {
        const category = response.data.data;

        dispatch({
          type: CATEGORY_EXPORT_LIST,
          payload: category || [],
        });

        return response;
      }
    } catch (error) {
      dispatch({
        type: CATEGORY_EXPORT_LIST,
        payload: [],
      });
      toast.error(error.message);
      console.error("API error:", error);
    }
  };
};

// get sub categories
export const getSubCategoryList = (search) => {
  return async (dispatch) => {
    try {
      let url = `/category/sub_categories`;
      if (search) {
        url = `${url}?search=${search}`;
      }
      const response = await getWithToken(url);

      if (response) {
        const category = response.data.data;

        dispatch({
          type: SUB_CATEGORY_LIST,
          payload: category,
        });
        return response;
      }
    } catch (error) {
      toast.error(error.message);
      console.error("API error:", error);
    }
  };
};

// delete category

export const getCategorydeleteList = (id, payload) => {
  return async () => {
    try {
      const response = await putWithToken(`/category/${id}`,payload);
      toast.loading("deleting.....");
      if (response) {
        const catgeory_delete_res = response;
        // console.log("catgeory_delete_res",catgeory_delete_res)
        toast.dismiss();
        toast.success(response.data.message);
        return response;
      }
    } catch (error) {
      toast.error(error.message);
      console.error("API error:", error); 
    }
  };
};

export const COUNTRIES_LIST = "COUNTRIES_LIST";

export const getCountryList = ({
  search,
  status,
  startDate,
  endDate,
  skip,
  limit,
}) => {
  return async (dispatch) => {
    try {
      let url = `/country/?skip=${skip || 0}&limit=${limit || 10}`; // Corrected base URL

      const queryParams = [];
      if (search) queryParams.push(`search=${search}`);
      if (status) queryParams.push(`status=${status}`);
      if (startDate) {
        queryParams.push(`start_ts=${startDate}`);
      }
      if (endDate) {
        queryParams.push(`end_ts=${endDate}`);
      }

      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }

      const response = await getWithToken(url);

      if (response) {
        const countriesList = response.data.data;
        dispatch({
          type: COUNTRIES_LIST,
          payload: countriesList || [],
          totalCount: response.data.count,
          skip: skip,
          limit: limit,
        });
        return response;
      } else {
        return [];
      }
    } catch (error) {
      dispatch({
        type: COUNTRIES_LIST,
        payload: [],
        skip: 0,
        limit: limit,
      });
      toast.error(error?.message);
    }
  };
};

export const COUNTRIES_AUTO_LIST = "COUNTRIES_AUTO_LIST";

export const getCountryAutoSuggestList = ({ search }) => {
  return async (dispatch) => {
    try {
      let url = `/country/default/country?term=${search}`;

      const response = await getWithToken(url);
      if (response) {
        const countriesList = response.data.data;
        dispatch({
          type: COUNTRIES_AUTO_LIST,
          payload: countriesList || [],
        });
        return response;
      } else {
        return [];
      }
    } catch (error) {
      dispatch({
        type: COUNTRIES_AUTO_LIST,
        payload: [],
      });
    }
  };
};

export const getCountryUpdationList = (payload) => {
  return async () => {
    try {
      const response = await putWithToken(
        `/country/${payload?.id}`,
        payload?.data
      );
      if (response) {
        const countryResponse = response.data.data;
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      toast.error(error?.message[0]?.msg);
      console.error("API error", error);
    }
  };
};

// categroy by id

// export const getCountryPostList = (
//   countryName,
//   countryArea,
//   countryCode,
//   sequence,
//   status,
//   metaDescription,
//   slug,
//   metaKeyword,
//   metaTitle,
//   canonicalUrl
// ) => {
//   return async (dispatch) => {
//     try {
//       const response = await postWithToken(
//         "/country",
//         countryName,
//         countryArea,
//         countryCode,
//         sequence,
//         status,
//         metaDescription,
//         slug,
//         metaKeyword,
//         metaTitle,
//         canonicalUrl
//       );

//       if (response) {
//         const countriesList = response.data.data;
//         dispatch({
//           type: COUNTRIES_LIST,
//           payload: countriesList || [],
//         });
//         toast.success("New Country Added Successfully!");
//         return response;
//       }
//     } catch (error) {
//       toast.error(error.message);
//       dispatch({
//         type: COUNTRIES_LIST,
//         payload: [],
//       });
//       console.error("error", error);
//     }
//   };
// };

export const getCountryPostList = (payload) => {
  return async () => {
    try {
      // Make a POST request to the API
      const response = await postWithToken("/country", payload);

      // Check if the response contains data
      if (response.data) {
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      toast.error(error?.message[0]?.msg || error?.message);
      console.error("API error:", error);
    }
  };
};

export const getCountrydeleteList = (id) => {
  return async () => {
    try {
      const response = await deleteWithToken(`/country/${id}`);
      toast.loading("deleting.....");

      if (response) {
        const catgeory_delete_res = response;
        // console.log("catgeory_delete_res",catgeory_delete_res)
        toast.dismiss();
        toast.success(response.data.message);
        return response;
      }
    } catch (error) {
      toast.error(error.message);
      console.error("API error:", error);
    }
  };
};

export const VIDEO_LIST = "VIDEO_LIST";
export const VIDEO_UPDATE_LIST = "VIDEO_UPDATE_LIST";

export const getVideoList = ({
  search,
  status,
  startDate,
  endDate,
  skip,
  limit,
  flag
}) => {
  return async (dispatch) => {
    try {
      let url = `/featured_video?skip=${skip || 0}&limit=${limit || 10}`;

      const queryParams = [];
      if (search) queryParams.push(`search=${search}`);
      if (flag !== undefined) queryParams.push(`flag=${flag}`); 
      if (status) queryParams.push(`status=${status}`);
      if (startDate) {
        queryParams.push(`start_ts=${startDate}`);
      }
      if (endDate) {
        queryParams.push(`end_ts=${endDate}`);
      }

      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }

      const response = await getWithToken(url);

      if (response) {
        const video = response.data.data;

        dispatch({
          type: VIDEO_LIST,
          payload: video || [],
          totalCount: response.data.count,
          skip: skip,
          limit: limit,
        });

        return response;
      }
    } catch (error) {
      dispatch({
        type: VIDEO_LIST,
        payload: [],
      });
      toast.error(error.message);
      console.error("API error:", error);
    }
  };
};

export const VIDEO_EXPORT = "VIDEO_EXPORT";

export const getExportVideoList = ({
  search,
  status,
  startDate,
  endDate,
  skip,
  limit,
}) => {
  return async (dispatch) => {
    try {
      let url = `/featured_video`;

      const queryParams = [];
      if (search) queryParams.push(`search=${search}`);
      if (status) queryParams.push(`status=${status}`);
      if (startDate) {
        queryParams.push(`start_ts=${startDate}`);
      }
      if (endDate) {
        queryParams.push(`end_ts=${endDate}`);
      }

      if (queryParams.length > 0) {
        url += `?${queryParams.join("&")}`;
      }

      const response = await getWithToken(url);

      if (response) {
        const video = response.data.data;

        dispatch({
          type: VIDEO_EXPORT,
          payload: video || [],
          totalCount: response.data.count,
          skip: skip,
          limit: limit,
        });

        return response;
      }
    } catch (error) {
      dispatch({
        type: VIDEO_EXPORT,
        payload: [],
      });
      toast.error(error.message);
      console.error("API error:", error);
    }
  };
};

// update category
export const getVideoUpdationList = (payload) => {
  return async (dispatch) => {
    try {
      const response = await putWithToken(
        `/featured_video/${payload?.id}`,
        payload.data
      );
      if (response) {
        const video = response.data.data;
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      toast.error(error?.message[0]?.msg);
      console.error("API error", error);
    }
  };
};

// categroy by id

export const getVideoPostList = (
  title,
  categoryId,
  duration,
  thumbnailUrl,
  status,
  metaDescription,
  slug,
  metaKeyword,
  metaTitle,
  canonicalUrl
) => {
  return async (dispatch) => {
    try {
      // Make a POST request to the API
      const response = await postWithToken(
        "/featured_video",
        title,
        categoryId,
        duration,
        thumbnailUrl,
        status,
        metaDescription,
        slug,
        metaKeyword,
        metaTitle,
        canonicalUrl
      );

      // Check if the response contains data
      if (response) {
        const videosList = response.data?.data;

        // Dispatch an action with the fetched data
        dispatch({
          type: VIDEO_LIST,
          payload: videosList || [],
        });
        toast.success("New Video Added successfully");
        return response;
      }
    } catch (error) {
      toast.error(
        error?.message?.[0].msg ? error?.message?.[0].msg : error?.message
      );
      // // throw error;
    }
  };
};

export const getVideodeleteList = (body) => {
  return async (dispatch) => {
    try {
      const response = await deleteWithToken(
        `/featured_video/delete`,{video_ids:body}
      );
      if (response) {
        const video = response.data.data;
        toast.success("Successfully deleted the videos");
        return response;
      }
    } catch (error) {
      toast.error(error.message);
      console.error("API error:", error);
    }
  };
};
// subscribe
export const getSubscriberList = ({
  skip,
  limit,
}) => {
  return async (dispatch) => {
    try {
      let url = `/subscription/all?skip=${skip || 0}&limit=${limit || 10}`;

      const response = await getWithToken(url);

      if (response) {
        const video = response.data.data;
        return response;
      }
    } catch (error) {
      dispatch({
        type: VIDEO_LIST,
        payload: [],
      });
      toast.error(error.message);
      console.error("API error:", error);
    }
  };
};

// content

export const CONTENT_LIST = "CONTENT_LIST";

export const getContentList = ({
  search,
  skip,
  limit,
  startDate,
  endDate,
  status,
}) => {
  return async (dispatch) => {
    try {
      let url = `/cms/pages?skip=${skip || 0}&limit=${limit || 10}`;

      const queryParams = [];
      if (status) queryParams.push(`status=${status}`);
      if (search) queryParams.push(`search=${search}`);
      // search
      if (startDate) {
        queryParams.push(`start_ts=${startDate}`);
      }
      if (endDate) {
        queryParams.push(`end_ts=${endDate}`);
      }

      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }

      const response = await getWithToken(url);

      if (response) {
        const contentList = response.data.data;
        dispatch({
          type: CONTENT_LIST,
          payload: contentList || [],
          totalCount: response.data.count,
          skip: skip,
          limit: limit,
        });
        return response;
      }
    } catch (error) {
      dispatch({
        type: CONTENT_LIST,
        payload: [],
      });
      console.error("API error:", error);
      toast.error(error?.message);
    }
  };
};

export const getContentUpdationList = (payload) => {
  return async () => {
    try {
      const response = await putWithToken(
        `/cms/pages/${payload._id}`,
        payload?.data
      );
      if (response) {
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      toast.error(error?.message[0]?.msg);
      console.error("API error", error);
    }
  };
};

export const getContentPostList = (payload) => {
  return async () => {
    try {
      // Make a POST request to the API
      const response = await postWithToken("/cms/pages", payload);

      // Check if the response contains data
      if (response.data) {
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      toast.error(error?.message[0]?.msg || error?.message);
      console.error("API error:", error);
    }
  };
};

export const getContentdeleteList = (id) => {
  return async () => {
    try {
      const response = await deleteWithToken(`/cms/pages/${id}`);
      if (response) {
        toast.success(response?.data?.message);
        // return response;
      }
    } catch (error) {
      toast.error(error.message);
      console.error("API error:", error);
    }
  };
};

export const ADS_LIST = "ADS_LIST";

export const getAdsList = ({
  search,
  status,
  startDate,
  endDate,
  skip,
  limit,
}) => {
  return async (dispatch) => {
    try {
      let url = `/ads?skip=${skip || 0}&limit=${limit || 10}`;
      const queryParams = [];
      if (search) queryParams.push(`search=${search}`);
      if (status) queryParams.push(`status=${status}`);
      if (startDate) {
        queryParams.push(`start_ts=${startDate}`);
      }
      if (endDate) {
        queryParams.push(`end_ts=${endDate}`);
      }

      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }
      const response = await getWithToken(url);

      if (response) {
        const ads = response?.data.data;

        dispatch({
          type: ADS_LIST,
          payload: ads || [],
          totalCount: response?.data.count,
          skip: skip,
          limit: limit,
        });
        return response;
      }
    } catch (error) {
      dispatch({
        type: ADS_LIST,
        payload: [],
      });
      toast.error(error.message);
      console.error("API error:", error);
    }
  };
};

// Delete api

export const getAdsdeleteList = (id) => {
  return async () => {
    try {
      const response = await deleteWithToken(`/ads/?ad_id=${id}`);
      toast.loading("deleting.....");
      if (response) {
        const catgeory_delete_res = response;
        // console.log("catgeory_delete_res",catgeory_delete_res)
        toast.dismiss();
        toast.success(response.data.message);
        return response;
      }
    } catch (error) {
      toast.error(error.message);
      console.error("API error:", error);
    }
  };
};
// post category
export const addCategory = (payload) => {
  return async (dispatch) => {
    try {
      // Make a POST request to the API
      const response = await postWithToken("/category", payload);

      // Check if the response contains data
      if (response.data) {
        const categroy = response.data.data;
        // Dispatch an action with the fetched data
        dispatch({
          type: CATEGORY_LIST,
          payload: categroy || [],
        });

        return response;
      }
    } catch (error) {
      toast.error(error?.message);
      dispatch({
        type: CATEGORY_LIST,
        payload: [],
      });

      // toast.error(error.message);
    }
  };
};
// update category
export const updateCategory = (payload) => {
  return async (dispatch) => {
    try {
      const response = await putWithToken(
        `/category/${payload?.id}`,
        payload.data
      );
      if (response) {
        const categoryResponse = response.data.data;
        toast.success(response?.data?.message);
        return response;
      }
    } catch (error) {
      // toast.error(error?.message);
      console.error("API error", error);
    }
  };
};

// post Ads

export const addAds = (payload) => {
  return async () => {
    try {
      // Make a POST request to the API
      const response = await postWithToken("/ads", payload);

      // Check if the response contains data
      if (response) {
        const ads = response.data;
        toast.success("New Article added successfully!!");
        return response;
      }
    } catch (error) {
      toast.error(error.msg || error.message[0].msg || error.message);
      console.error("API error:", error.message);
    }
  };
};

// update category

export const getAdsUpdationList = (payload) => {
  return async () => {
    try {
      const response = await putWithToken(`/ads/${payload._id}`, payload?.data);
      if (response) {
        const AdsResponse = response.data.data;
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      toast.error(error?.message[0]?.msg);
      console.error("API error", error);
    }
  };
};

export const ARTICLES_LIST = "ARTICLES_LIST";

export const getArticleList = ({
  search,
  status,
  startDate,
  endDate,
  skip,
  limit,
  article_type,
  categories,
}) => {
  return async (dispatch) => {
    try {
      let url = `/articles?skip=${skip || 0}&limit=${
        limit || 10
      }&article_type=${article_type}`;

      const queryParams = [];
      if (search) queryParams.push(`search=${search}`);
      if (status) queryParams.push(`status=${status}`);
      if (startDate) {
        queryParams.push(`start_ts=${startDate}`);
      }
      if (endDate) {
        queryParams.push(`end_ts=${endDate}`);
      }
      if (categories) {
        queryParams.push(`categories=${categories}`);
      }
      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }
      const response = await getWithToken(url);
      if (response) {
        const article = response.data.data;
        if (article_type === "top story") {
          dispatch({
            type: ARTICLES_LIST,
            payload: article || [],
            totalCount: response?.data.count,
            skip: skip,
            limit: limit,
            tarticle: skip,
          });
        } else if (article_type === "featured") {
          dispatch({
            type: ARTICLES_LIST,
            payload: article || [],
            totalCount: response?.data.count,
            skip: skip,
            limit: limit,
            trackSkip: skip,
          });
        } else if (article_type === "narrative") {
          dispatch({
            type: ARTICLES_LIST,
            payload: article || [],
            totalCount: response?.data.count,
            skip: skip,
            limit: limit,
            narticle: skip,
          });
        } else {
          dispatch({
            type: ARTICLES_LIST,
            payload: article || [],
            totalCount: response?.data.count,
            skip: skip,
            limit: limit,
            auth_article: skip,
          });
        }
        return article;
      } else {
        return [];
      }
    } catch (error) {
      dispatch({
        type: ARTICLES_LIST,
        payload: [],
      });
      console.error("error", error);
      //
    }
  };
};

export const getArticlePostList = (payload) => {
  return async (dispatch) => {
    try {
      // Make a POST request to the API
      const response = await postWithToken("/articles", payload);
      // console.log("response", response);
      // Check if the response contains data
      if (response) {
        const article_post_res = response.data;

        toast.success("New Article added successfully!!");
        return response;
      }
    } catch (error) {
      console.error("erroor", error);
      toast.error(error?.msg || error.message[0].msg || error.message);
      console.error("API error:", error.message);
    }
  };
};
export const getArticleUpdationList = (payload) => {
  return async () => {
    try {
      const response = await putWithToken(
        `/articles/${payload._id}`,
        payload?.data
      );
      if (response) {
        const AdsResponse = response.data.data;
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      toast.error(error?.message[0]?.msg);
      console.error("API error", error);
    }
  };
};

// Delete api

export const getArticledeleteList = (body) => {
  return async (dispatch) => {
    try {
      const response = await deleteWithToken(`/articles/delete`,{articleIds:body});

      if (response) {
        const article = response.data.data;

        toast.success("Article deleted successfully!");
        return response;
      }
    } catch (error) {
      toast.error(error.msg || error.message[0].msg);
      console.error("API error:", error);
    }
  };
};

//  upload image

// Ads

export const IMAGE_LIST = "IMAGE_LIST";

export const getImageList = (formData) => {
  return async (dispatch) => {
    try {
      // let url = `https://api-cms.ahn24x7.com/api/v1/uploadfiles/`;
      toast.loading("uploading..please wait..");

      const response = await postFormDataWithToken("/uploadfiles", formData);

      if (response) {
        const image = response.data.data;
        dispatch({
          type: IMAGE_LIST, // Change this to IMAGE_LIST
          payload: image || [],
        });
        toast.dismiss();
        if (image.length === 0) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
        }

        return response;
      }
    } catch (error) {
      console.error("error", error);
      toast.dismiss();
      toast.error(error.message);
    }
  };
};

// post podcast
export const addPodcast = (payload) => {
  return async (dispatch) => {
    try {
      // Make a POST request to the API
      const response = await postWithToken("/podcast/", payload);
      // console.log("response.data", response);
      // Check if the response contains data
      if (response.data?.length || response.data != "") {
        const categroy = response.data.data;
        // Dispatch an action with the fetched data
        // dispatch({
        //   type: CATEGORY_LIST,
        //   payload: categroy || [],
        // });

        return categroy;
      }
    } catch (error) {
      console.error("error", error);
      toast.error(error?.message ? error.message : "Error occurred !!");
    }
  };
};

// Edit podcast
export const updatePodcast = (payload) => {
  return async (dispatch) => {
    try {
      const response = await putWithToken(
        `/podcast/${payload?.id}`,
        payload.data
      );
      if (response) {
        const category = response.data.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("error", error);
    }
  };
};

// get all podcast
export const getAllPodcastList = ({ skip, limit, search, flag }) => {
  return async (dispatch) => {
    try {
      let url = `/podcast/?skip=${skip || 0}&limit=${limit || 10}`;
      const queryParams = [];
      if (search) queryParams.push(`search=${search}`);
      if (flag !== undefined) queryParams.push(`flag=${flag}`); 

      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }
      const response = await getWithToken(url);
      if (response) {
        const podcast = response.data.data;
        dispatch({
          type: PODCAST_LIST,
          payload: podcast || [],
          totalCount:response.data.count||0,
        });
        return response;
      }
    } catch (error) {
      dispatch({
        type: PODCAST_LIST,
        payload: [],
      });
      console.error("error", error);
      toast.error(error?.message);
    }
  };
};

// delete podcast

export const deletePodcast = (body) => {
  return async (dispatch) => {
    try {
      const response = await deleteWithToken(`/podcast/delete`,{podcastIds:body});

      if (response) {
        const podcast = response.data;
        // dispatch({
        //   type: PODCAST_LIST,
        //   payload: podcast || [],
        // });
        return podcast;
      } else {
        return [];
      }
    } catch (error) {
      console.error("error", error);
      toast.error("Error occurred !!");
    }
  };
};

// IMAGE GALLERY

export const IMAGE_GALLERY_LIST = "IMAGE_GALLERY_LIST";

export const getImageGalleryList = ({
  search,
  status,
  startDate,
  endDate,
  skip,
  limit,
  flag
}) => {
  return async (dispatch) => {
    try {
      let url = `/image_gallery/?skip=${skip}&limit=${limit}`;
      const queryParams = [];
      if (search) queryParams.push(`search=${search}`);
      if (flag !== undefined) queryParams.push(`flag=${flag}`); 

      if (status) queryParams.push(`status=${status}`);
      if (startDate) {
        queryParams.push(`start_ts=${startDate}`);
      }
      if (endDate) {
        queryParams.push(`end_ts=${endDate}`);
      }

      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }
      const response = await getWithToken(url);

      if (response) {
        const ImagesData = response.data.data;
        dispatch({
          type: IMAGE_GALLERY_LIST,
          payload: ImagesData || [],

          totalCount: response.data.count,
          skip: skip,
          limit: limit,
        });
        return response;
      }
    } catch (error) {
      dispatch({
        type: IMAGE_GALLERY_LIST,
        payload: [],
      });
      console.error("error", error);
      toast.error(error.message);
    }
  };
};

export const getImageGallerydeleteList = (body) => {
  return async () => {
    try {
      const response = await deleteWithToken(`/image_gallery/delete`,{imageGalleryIds:body});
      toast.loading("deleting.....");

      if (response) {
        const image_gallery = response;
        // console.log("catgeory_delete_res",catgeory_delete_res)
        toast.dismiss();
        toast.success(response.data.message);
        return response;
      }
    } catch (error) {
      toast.error(error.message);
      console.error("API error:", error);
    }
  };
};

export const getImagePostList = (payload) => {
  return async (dispatch) => {
    try {
      // Make a POST request to the API
      const response = await postWithToken("/image_gallery/", payload);

      // Check if the response contains data
      if (response?.data?.status == 200) {
        toast(response?.data?.message);
        // const article = response.data.data;
        // console.log("response.data.data", response.data.data);

        // Dispatch an action with the fetched data
        // dispatch({
        //   type: IMAGE_GALLERY_LIST,
        //   payload: article || [],
        // });

        return response;
      } else {
        return [];
      }
    } catch (error) {
      console.error("error", error);
    }
  };
};

export const getImageUPdateionList = (payload) => {
  return async (dispatch) => {
    try {
      const response = await putWithToken(
        `/image_gallery/${payload?._id}`,
        payload.data
      );
      if (response?.status == 200) {
        return response;
      }
    } catch (error) {
      console.error("error", error);
    }
  };
};

// get Dashobard

export const DASHBOARD_CONTENT = "DASHBOARD_CONTENT";

export const getdashboardContent = () => {
  return async (dispatch) => {
    try {
      let url = `/dashboard`;
      const response = await getWithToken(url);
      if (response) {
        const dashboard = response.data.data;
        dispatch({
          type: DASHBOARD_CONTENT,
          payload: dashboard || [],
        });
        return response;
      }
    } catch (error) {
      dispatch({
        type: DASHBOARD_CONTENT,
        payload: [],
      });
      toast.error(error.message);
      console.error("API error:", error);
    }
  };
};

export const DASHBOARD_COUNT = "DASHBOARD_COUNT";

export const getdashboardCount = () => {
  return async (dispatch) => {
    try {
      let url = `/dashboard/count`;
      const response = await getWithToken(url);
      if (response) {
        const dashboard = response.data.data;
        dispatch({
          type: DASHBOARD_COUNT,
          payload: dashboard || [],
        });
        return response;
      }
    } catch (error) {
      dispatch({
        type: DASHBOARD_COUNT,
        payload: [],
      });
      toast.error(error.message);
      console.error("API error:", error);
    }
  };
};

// export all Article api
export const getAllArticleExportList = ({
  search,
  status,
  startDate,
  endDate,
  article_type,
}) => {
  return async (dispatch) => {
    try {
      let url = `/articles/export/article?article_type=${article_type}`; // Corrected base URL

      const queryParams = [];
      if (search) queryParams.push(`search=${search}`);
      if (status) queryParams.push(`status=${status}`);
      if (startDate) {
        queryParams.push(`start_ts=${startDate}`);
      }
      if (endDate) {
        queryParams.push(`end_ts=${endDate}`);
      }

      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }

      const response = await getWithToken(url);

      if (response) {
        const category = response.data.data;

        dispatch({
          type: CATEGORY_EXPORT_LIST,
          payload: category || [],
        });

        return response;
      }
    } catch (error) {
      dispatch({
        type: CATEGORY_EXPORT_LIST,
        payload: [],
      });
      // toast.error("No article found for export");
      console.error("API error:", error);
    }
  };
};

// home page api

// IMAGE GALLERY

export const GET_HOME_PAGE_DATA = "GET_HOME_PAGE_DATA";

export const getHomePageData = () => {
  return async (dispatch) => {
    try {
      let url = `/home_page/cms`;
      const response = await getWithToken(url);

      // console.log("response of homepage", response);
      if (response?.data?.status == 200) {
        dispatch({
          type: GET_HOME_PAGE_DATA,
          payload: response?.data?.data || [],
        });
        return response?.data;
      }

      // console.log("response of home page data", response);
    } catch (error) {
      console.error("error", error);
      toast.error(error.message);
    }
  };
};

export const getExportCountryList = ({
  search,
  status,
  startDate,
  endDate,
  skip,
  limit,
}) => {
  return async (dispatch) => {
    try {
      let url = `/country/`; // Corrected base URL

      const queryParams = [];
      if (search) queryParams.push(`search=${search}`);
      if (status) queryParams.push(`status=${status}`);
      if (startDate) {
        queryParams.push(`start_ts=${startDate}`);
      }
      if (endDate) {
        queryParams.push(`end_ts=${endDate}`);
      }

      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }

      const response = await getWithToken(url);

      if (response) {
        const countriesList = response.data.data;
        dispatch({
          type: COUNTRIES_LIST,
          payload: countriesList || [],
          totalCount: response.data.count,
          skip: skip,
          limit: limit,
        });
        return response;
      } else {
        return [];
      }
    } catch (error) {
      dispatch({
        type: COUNTRIES_LIST,
        payload: [],
        skip: 0,
        limit: limit,
      });
    }
  };
};

// home page

export const HOME_PAGE_ALL_FEATURED_LIST = "HOME_PAGE_ALL_FEATURED_LIST";

export const getHomePageAllFeatureList = ({
  skip,
  limit,
  article_type,
  search,
  flag
}) => {
  return async (dispatch) => {
    try {
      let url = `/articles?skip=${skip || 0}&limit=${
        limit || 10
      }&article_type=${article_type}`;
      const queryParams = [];
      if (search) queryParams.push(`search=${search}`);
      if (flag !== undefined) queryParams.push(`flag=${flag}`); 

      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }
      const response = await getWithToken(url);
      if (response) {
        const article = response.data;
        dispatch({
          type: HOME_PAGE_ALL_FEATURED_LIST,
          payload: article || [],
        });
        return response;
      }
    } catch (error) {
      dispatch({
        type: HOME_PAGE_ALL_FEATURED_LIST,
        payload: [],
      });
      console.error("error", error);
      toast.error(error.message);

      //
    }
  };
};

export const HOME_PAGE_ALL_TOP_STORIES_LIST = "HOME_PAGE_ALL_TOP_STORIES_LIST";

export const getHomePageAlltTopStoriesList = ({
  skip,
  limit,
  article_type, 
  search,
  flag
}) => {
  return async (dispatch) => {
    try {
      let url = `/articles?skip=${skip || 0}&limit=${
        limit || 10
      }&article_type=${article_type}`;
      const queryParams = [];
      if (search) queryParams.push(`search=${search}`);
      if (flag !== undefined) queryParams.push(`flag=${flag}`); 

      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }

      const response = await getWithToken(url);
      if (response) {
        const article = response.data;
        dispatch({
          type: HOME_PAGE_ALL_TOP_STORIES_LIST,
          payload: article || [],
          totalCount: response?.data.count,
          skip: skip,
          limit: limit,
        });
        return article;
      } else {
        return [];
      }
    } catch (error) {
      dispatch({
        type: HOME_PAGE_ALL_TOP_STORIES_LIST,
        payload: [],
      });
      console.error("error", error);
      toast.error(error.message);

      //
    }
  };
};

export const HOME_PAGE_ALL_NARRATIVE_LIST = "HOME_PAGE_ALL_NARRATIVE_LIST";

export const getHomePageAllNarrativeList = ({
  skip,
  limit,
  article_type,
  search,
  flag
}) => {
  return async (dispatch) => {
    try {
      let url = `/articles?skip=${skip || 0}&limit=${
        limit || 10
      }&article_type=${article_type}`;

      const queryParams = [];
      if (search) queryParams.push(`search=${search}`);
      if (flag !== undefined) queryParams.push(`flag=${flag}`); 

      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }
      const response = await getWithToken(url);
      if (response) {
        const article = response.data.data;
        dispatch({
          type: HOME_PAGE_ALL_NARRATIVE_LIST,
          payload: article || [],
          totalCount: response?.data.count,
        });
        return response;
      } else {
        return [];
      }
    } catch (error) {
      dispatch({
        type: HOME_PAGE_ALL_NARRATIVE_LIST,
        payload: [],
      });
      console.error("error", error);
      toast.error(error.message);

      //
    }
  };
};

export const HOME_PAGE_ALL_OPINION_LIST = "HOME_PAGE_ALL_OPINION_LIST";

export const getHomePageAllOpinionList = ({
  skip,
  limit,
  article_type,
  search,
  flag
}) => {
  return async (dispatch) => {
    try {
      let url = `/articles?skip=${skip || 0}&limit=${
        limit || 10
      }&article_type=${article_type}`;

      const queryParams = [];
      if (search) queryParams.push(`search=${search}`);
      if (flag !== undefined) queryParams.push(`flag=${flag}`); 

      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }
      const response = await getWithToken(url);
      if (response) {
        const article = response.data.data;
        dispatch({
          type: HOME_PAGE_ALL_OPINION_LIST,
          payload: article || [],
          totalCount: response?.data.count,
        });
        return response;
      } else {
        return [];
      }
    } catch (error) {
      dispatch({
        type: HOME_PAGE_ALL_OPINION_LIST,
        payload: [],
      });
      console.error("error", error);
      toast.error(error.message);

      //
    }
  };
};

export const HOME_PAGE_ALL_AUTHOR_ARTICLE_LIST =
  "HOME_PAGE_ALL_AUTHOR_ARTICLE_LIST";

export const getHomePageAllAuthorAritcleList = ({
  skip,
  limit,
  article_type,
  search,
  flag
}) => {
  return async (dispatch) => {
    try {
      let url = `/articles?skip=${skip || 0}&limit=${
        limit || 10
      }&article_type=${article_type}`;
      const queryParams = [];

      if (search) queryParams.push(`search=${search}`);
      if (flag !== undefined) queryParams.push(`flag=${flag}`); 
      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }
      const response = await getWithToken(url);

      if (response) {
        const article = response.data;
        dispatch({
          type: HOME_PAGE_ALL_AUTHOR_ARTICLE_LIST,
          payload: article || [],
          totalCount: response?.data.count,
          skip: skip,
          limit: limit,
        });
        return article;
      } else {
        return [];
      }
    } catch (error) {
      dispatch({
        type: HOME_PAGE_ALL_AUTHOR_ARTICLE_LIST,
        payload: [],
      });
      console.error("error", error);
      toast.error(error.message);

      //
    }
  };
};

export const GET_ALL_NEWS = "GET_ALL_NEWS";

export const getAllNews = ({
  search,
  skip,
  limit,
  category,
  country,
  startDate,
  endDate,
}) => {
  return async (dispatch) => {
    try {
      let url = `/home_page/news?skip=${skip || 0}&limit=${limit || 10}`;
      const queryParams = [];

      if (search) queryParams.push(`search=${search}`);
      if (category) queryParams.push(`category=${category}`);
      if (country) queryParams.push(`country=${country}`);
      if (startDate) {
        queryParams.push(`start_ts=${startDate}`);
      }
      if (endDate) {
        queryParams.push(`end_ts=${endDate}`);
      }
      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }
      const response = await getWithToken(url);
      if (response) {
        const article = response.data;
        dispatch({
          type: GET_ALL_NEWS,
          payload: article || [],
        });
        return response;
      }
    } catch (error) {
      dispatch({
        type: GET_ALL_NEWS,
        payload: [],
      });
      console.error("error", error);
      toast.error(error.message);

      //
    }
  };
};

export const deleteNews = (body) => {
  return async (dispatch) => {
    try {
      let url = `/home_page/news/delete`;
      const response = await deleteWithToken(url,{news_ids:body});
      if (response?.status == 200) {
        toast(response?.data.message);
        
      }
      return response;
    } catch (error) {
      console.error("error", error);
      toast.error(error.message);
    }
  };
};

export const REPLACE_HOME_PAGE_NEWS = "REPLACE_HOME_PAGE_NEWS";

export const replaceHomePageNews = ({ oldId, newId, collectionType }) => {
  return async (dispatch) => {
    try {
      toast.loading("Updating..please wait..");
      let url = `/home_page/replace?older_id=${oldId}&new_id=${newId}&collection_type=${collectionType}`;
      const response = await putWithToken(url);
      toast.success(response?.data?.message);
      toast.dismiss();
      return response;
    } catch (error) {
      dispatch({
        type: REPLACE_HOME_PAGE_NEWS,
        payload: [],
      });
      console.error("error", error);
      toast.dismiss();
      toast.error(error.message);

      //
    }
  };
};

export const publishHomePageData = () => {
  return async () => {
    try {
      toast.loading("Publishing..please wait..");
      const response = await postWithToken("/home_page/publish");
      toast.dismiss();
      if (response) {
        return response;
      }
    } catch (error) {
      toast.dismiss();
      console.error("error", error);
      toast.error(error.message);
    }
  };
};

export const getHomepageapiUpdation = (payload) => {
  return async () => {
    try {
      toast.loading("Updating..please wait..");
      const response = await putWithToken(`/home_page/add?`, payload?.data);
      toast.dismiss();

      if (response) {
        const homeResponse = response.data.data;
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      toast.dismiss();
      toast.error(error?.message || error?.message[0]?.msg);
      console.error("API error", error);
    }
  };
};

export const getSequenceSwapApi = (payload) => {
  return async () => {
    try {
      toast.loading("Updating..please wait..");
      const response = await putWithToken(`/home_page/sequence`, payload?.data);
      toast.dismiss();

      if (response) {
        const swapResponse = response.data.data;
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      toast.dismiss();
      toast.error(error?.message || error?.message[0]?.msg);
      console.error("API error", error);
    }
  };
};

export const getSequenceSwapByInputApi = (payload) => {
  return async () => {
    try {
      toast.loading("Updating..please wait..");
      const response = await putWithToken(
        `/home_page/individual_sequence`,
        payload?.data
      );
      toast.dismiss();
      if (response) {
        const swapResponse = response.data.data;
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      toast.dismiss();
      console.error("error", error.message);
      toast.error(error.message);
      console.error("API error", error);
    }
  };
};

export const GET_DRAFT_NEWS = "GET_DRAFT_NEWS";

export const getDraftNews = () => {
  return async (dispatch) => {
    try {
      let url = `/home_page/draft`;

      const response = await getWithToken(url);
      if (response) {
        const article = response.data.data;

        dispatch({
          type: GET_DRAFT_NEWS,
          payload: article || [],
        });
        return article;
      } else {
        return [];
      }
    } catch (error) {
      dispatch({
        type: GET_DRAFT_NEWS,
        payload: [],
      });
      console.error("error", error);
      toast.error(error.message);

      //
    }
  };
};

// get User List

export const GET_USERS_LIST = "GET_USERS_LIST";
export const getUserList = ({
  search,
  status,
  startDate,
  endDate,
  skip,
  limit,
  userType,
}) => {
  return async (dispatch) => {
    try {
      let url = `/auth/role/${userType}?skip=${skip || 0}&limit=${limit || 10}`; // Corrected base URL

      const queryParams = [];
      if (search) queryParams.push(`search=${search}`);
      if (status) queryParams.push(`status=${status}`);
      if (startDate) {
        queryParams.push(`start_ts=${startDate}`);
      }
      if (endDate) {
        queryParams.push(`end_ts=${endDate}`);
      }
      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }
      const response = await getWithToken(url);

      if (response) {
        const userList = response?.data;

        dispatch({
          type: GET_USERS_LIST,
          payload: userList || [],
          skip: skip,
          limit: limit,
        });

        return response;
      }
    } catch (error) {
      dispatch({
        type: GET_USERS_LIST,
        payload: [],
      });
      toast.error(error.message || error.detail[0].msg);
      console.error("API error:", error);
    }
  };
};

export const postRegisterEditor = (payload) => {
  return async function (dispatch) {
    toast.loading("Loading....");
    try {
      const response = await postWithToken("/auth/register", payload);

      if (response.status === 200) {
        var result = response?.data;
        toast.success(response?.data?.status);
        return result;
      }
      toast.dismiss();
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
      console.error("Login error:", error);
    }
  };
};
export const patchRegisterEditor = (payload) => {
  return async function (dispatch) {
    toast.loading("Loading....");
    try {
      const response = await postWithToken("/auth/register", payload);
      // console.log("response of register editor", response?.data);
      if (response.status === 200) {
        var result = response?.data;
        toast.success(response?.data?.status);
        return result;
      }
      toast.dismiss();
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
      console.error("Login error:", error);
    }
  };
};

export const deleteEditor = ({ id }) => {
  return async (dispatch) => {
    try {
      let url = `/auth/${id}`;
      const response = await deleteWithToken(url);
      if (response?.status == 200) {
        toast(response?.data.message);
        return response;
      }
    } catch (error) {
      console.error("error", error);
      toast.error(error.message);
    }
  };
};

// categroy sequence swap

export const getCategroySequenceSwapApi = (payload) => {
  return async () => {
    try {
      toast.loading("Updating..please wait..");
      const response = await putWithToken(
        `/category/swap/sequence`,
        payload?.data
      );
      toast.dismiss();

      if (response) {
        const swapResponse = response.data.data;
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      toast.dismiss();
      toast.error(error?.message || error?.message[0]?.msg);
      console.error("API error", error);
    }
  };
};

export const getSequenceSwapByCategroyApi = (payload) => {
  return async () => {
    try {
      toast.loading("Updating..please wait..");
      const response = await putWithToken(
        `/category/swap/individual/sequence`,
        payload?.data
      );
      toast.dismiss();
      if (response) {
        const swapResponse = response.data.data;
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
      console.error("API error", error);
    }
  };
};

export const GET_ALL_COMMENTS = "GET_ALL_COMMENTS";

export const getAllComments = ({
  search,
  skip,
  limit,
  category,
  startDate,
  endDate,
  article_type,
}) => {
  return async (dispatch) => {
    try {
      let url = `/comments/all?skip=${skip || 0}&limit=${
        limit || 10
      }&article_type=${article_type}`;
      const queryParams = [];

      if (search) queryParams.push(`search=${search}`);
      if (category) queryParams.push(`category=${category}`);
      if (startDate) {
        queryParams.push(`start_ts=${startDate}`);
      }
      if (endDate) {
        queryParams.push(`end_ts=${endDate}`);
      }
      if (queryParams.length > 0) {
        url += `&${queryParams.join("&")}`;
      }
      const response = await getWithToken(url);
      if (response) {
        const comments = response.data;
        dispatch({
          type: GET_ALL_COMMENTS,
          payload: comments || [],
        });
        return response;
      }
    } catch (error) {
      dispatch({
        type: GET_ALL_COMMENTS,
        payload: [],
      });
      console.error("error", error);
      toast.error(error.message);

      //
    }
  };
};

export const GET_COMMENTS_BY_ID = "GET_COMMENTS_BY_ID";

export const getCommentsById = ({ id, skip, limit, article_type }) => {
  return async (dispatch) => {
    try {
      let url = `/comments/article/${id}?skip=${skip || 0}&limit=${
        limit || 10
      }&article_type=${article_type},
`;
      const response = await getWithToken(url);
      if (response) {
        const data = response.data;
        dispatch({
          type: GET_COMMENTS_BY_ID,
          payload: data || [],
        });
        return response;
      }
    } catch (error) {
      dispatch({
        type: GET_COMMENTS_BY_ID,
        payload: [],
      });
      console.error("error", error);
      toast.error(error.message);

      //
    }
  };
};

export const updateCommentStatusById = (payload) => {
  return async () => {
    try {
      const response = await putWithToken(
        `/comments/${payload._id}`,
        payload?.data
      );
      if (response) {
        toast.success(response?.data?.message);
      }
      return response;
    } catch (error) {
      toast.error(error?.message[0]?.msg);
      console.error("API error", error);
    }
  };
};

export const logOut = () => {
  return async () => {
    toast.loading("Logging out....");
    try {
      const response = await getWithToken(`/auth/logout`);
      if (response?.status == 200) {
        toast.success(response?.data?.message);
        toast.dismiss();
      }
      return response;
    } catch (error) {
      toast.dismiss();
      toast.error(error?.message[0]?.msg);
      console.error("API error", error);
    }
  };
};

export const getBacklogs = ({ skip,
  limit}) => {
  return async () => {
    try {
      let url = `/backlog/list?skip=${skip}&limit=${limit}`;
      const response = await getWithToken(url);
      return response;
      
    } catch (error) {
      toast.error(error.message);
      console.error("API error:", error);
    }
  };
};
// export const getEditerBacklogs = ({ skip,
//   limit}) => {
//   return async () => {
//     try {
//       let url = `/backlog/list?skip=${skip}&limit=${limit}`;
//       const response = await getWithToken(url);
//       return response;
      
//     } catch (error) {
//       toast.error(error.message);
//       console.error("API error:", error);
//     }
//   };
// };

export const getBacklogsByID = ({id}) => {
  return async () => {
    try {
      let url = `/backlog/${id}`;
      const response = await getWithToken(url);
      return response;
      
    } catch (error) {
      toast.error(error.message);
      console.error("API error:", error);
    }
  };
};

export const submitReview = (payload) => {
  return async function (dispatch) {
    toast.loading("Loading....");
    try {
      const response = await postWithToken("/backlog/document/review", payload);
      var result = response?.data;
      toast.dismiss();
      toast.success(response?.data?.status);
      return result;
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
      console.error("Login error:", error);
    }
  };
};

// getWithToken
